.p-15 {
    padding: 15px;
  
  }
.position {
    text-align: left;
}
  .profile-content {
    height: 100%;
    min-height: calc(100vh - 74px);
    margin-top: 74px;
  }

  .h-100 {
    height: 100%;
  }

  .card-main {
    background: #fff;
    padding: 30px;
    /* box-shadow: 0px 3px 30px #0000000D; */
    border-radius: 10px;
  }

  .change-password-main {
    min-width: 370px;
  }

  .title-main .page-title {
    font-size: 18px;
    line-height: 20px;
    color: #11074C;
    font-family: 'poppins-bold';
    text-transform: capitalize;
  }

  .title-main .page-title {
    color: #122B45;
    font-size: 16px;
    line-height: 20px;
  
  }

  .change-password-set {
    margin-bottom: 20px;
  }

  .detail-text-main .page-title {
    font-size: 14px;
    color: #2B478B;
    font-family: 'poppins-regular';
    line-height: 22px;
  }

  .title-main .page-title {
    color: #122B45;
    font-size: 16px;
    line-height: 20px;
  
  }
  .title-main .page-title {
    font-size: 18px;
    line-height: 20px;
    color: #11074C;
    font-family: 'poppins-bold';
    text-transform: capitalize;
  }
  
  .change-space {
    margin-bottom: 15px;
  }

  .change-input-label {
    color: #2B478B;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 5px !important;
    margin-left: 5px !important;
    padding-bottom: 0px !important;
    display: flex;
    /* font-family: 'poppins-regular'; */
    font-family: 'poppins-semibold';
  }

  .input-design-div.with-border input {
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0 15px;
    height: 40px;
  
    line-height: 28px;
  }


  .with-border .MuiFilledInput-underline {
    height: 40px !important;
    font-size: 14px !important;
    letter-spacing: 0;
  }

  .input-design-div.with-border input {
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0 15px;
    height: 40px;
    line-height: 28px;
  }
  
  .change-space {
    margin-bottom: 15px;
  }
  

  /* .input-design-div .password {
    margin-top: 20px !important;
  } */

  .admin-input-design {
  
    width: 100%;
  }

  .input-design-div .input-placeholder input::placeholder {
    color: #02091c !important;
    line-height: 18px !important;
    margin-top: 2px !important;
    /* font-family: 'poppines-bold' !important; */
    font-weight: 800 !important;
    font-size: 13.5px !important;
  
  }
