.admin-form-group .admin-form-control input {
      border: 1px solid #80808059;
      border-radius: 4px;
      width: 100%;
      padding: 10px 15px;
      background-color: transparent;
      color: gray;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
}

.admin-form-group .admin-form-control input::placeholder {
      color:gray;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      opacity: 1;
}

.admin-form-group .admin-form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border-width: 0px;
}

.admin-form-control fieldset {
      border: 0px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: #80808059 !important;
}

.distance-row{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}
.common-card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    border: 1px solid #d4d3d3;
    padding: 25px;
    margin-left: 25px;
    width: max-content;
}
.distance-btn-main .distance-btn{
    font-size: 15px;
    line-height: 20px;
    padding: 15px;
    min-width: max-content;
    color: #fff;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
}
