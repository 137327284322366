.container{
    margin-top: 10% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
}

.css-5lbw0b-MuiTypography-root{
    font-family: 'poppins-regular' !important;
}

