/* .heading-text{
    margin-left: 15px !important;
} */

*{
    font-family: 'poppins-regular';
  }
  .input-size{
    padding-top: 10px !important;
  }
  
  .dashboard-content .admin-page-title {
    margin-top: 2%;
    color: #111827;
    font-size: 22px;
    line-height: 39px;
    font-family: "Poppins-SemiBold";
    margin-bottom: 20px;
    margin-left: 15px;
  }
  
  .user-list-flex {
margin-top: 95px;
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .userlist-btn-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* .categorylist-img {
    margin-right: 15px;
    height: 50px;
    width: 50px;
    object-fit: cover;

  } */
  
  .otp-input-box .form-group .form-control input {
    max-width: 70px;
    text-align: center;
    color: #6b7280;
    font-size: 13px;
    line-height: 20px;
    font-family: "Poppins-Regular";
  }
  
  .otp-input-box .form-group .MuiFormControl-root {
    max-width: 70px;
    padding: 4px;
  }
  
  .otp-input-box .form-group .form-control input::placeholder {
    color: #6b7280;
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins-Regular";
  }
  
  .progress-bar-content .admin-page-title {
    font-size: 20px;
    line-height: 30px;
    color: #111827;
    font-family: "Poppins-Medium";
  }
  
  .progress-bar-text {
    position: absolute;
    right: -35px;
    top: -5px;
    font-size: 14px;
    line-height: 21px;
    color: #6b7280;
    font-family: "Poppins-Medium";
  }
  
  .user-list-flex .user-list-page-title {
    margin-bottom: 0px;
  }
  
  .user-search-box {
    position: relative;
    margin-right: 10px;
  }
  
  .user-search-box .form-control input {
    padding: 0px 0px 0px 30px;
  }
  
  .user-search-box .form-control input::placeholder {
    color: #6b7280;
    font-size: 13px;
    line-height: 20px;
    font-family: "Poppins-Regular";
    opacity: 1;
  }
  
  .form-group .form-control input {
    border: 1px solid #d1d5db;
    border-radius: 4px;
    width: 100%;
    /* padding: 9px 15px; */
    background-color: transparent;
    color: #6b7280;
    font-size: 13px;
    line-height: 19px;
    font-family: "Poppins-Regular";
  }
  
  .form-group .form-control input::placeholder {
    color: #6b7280;
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins-Regular";
    opacity: 1;
  }
  
  .form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    border-width: 0px;
  }
  .form-group .errormsg {
    color: red;
    font-size: 12px;
    padding: 4px;
  }
  
  
  .form-group .field-arrow-control input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  
  .form-group
    .field-arrow-control
    input[type="number"]::-webkit-outer-spin-button,
  .form-group
    .field-arrow-control
    input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .change-pass-input-box .form-group .form-control input {
    border: 1px solid #d1d5db;
    border-radius: 4px;
    width: 100%;
    padding: 5.5px 15px;
    background-color: transparent;
    color: #6b7280;
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins-Regular";
  }
  
  .dropdown-box .form-control {
    width: 100%;
    position: relative;
  }
  
  .form-control fieldset {
    border: 1px solid !important ;
    border-color: rgba(0, 0, 0, 0.23) !important;
    border-radius: 4px !important;
  }
  
  .otp-input-box .form-group .form-control input::placeholder {
    color: #6b7280;
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins-Regular";
  }
  
  .search-grey-img {
    position: absolute;
    left: 13px;
    top: 13px;
    height: 13px;
    width: 10px;
    margin-right: 8px;
  }
  
  .flex-all {
    display: flex;
    justify-content: space-between;
  }
  
  .adduser-btn-main .adduser-btn,
  .adduser-btn-main .adduser-btn:hover {
    min-width: 115px;
    height: 36px;
  }
  
  .btn-main-primary .btn-primary,
  .btn-main-primary .btn-primary:hover {
    background-color: #233862;
    border-radius: 3px;
    width: fit-content;
    max-width: 380px;
    color: #ffffff;
    font-size: 14px;
    line-height: 21px;
    font-family: "Poppins-Regular";
    height: 44px;
    text-transform: capitalize;
  }
  
  .plus-icon {
    width: 15px;
    height: 10px;
    object-fit: contain;
    margin-right: 9px;
  }
  
  .grid-main .grid-item {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0px;
    padding: 0px;
  }
  
  .display-row > * {
    padding: 0px 25px;
  }
  
  /* .admin-dash-box {
    box-shadow: rgba(0, 0, 0, 0.32) 1px 1px 8px 0px;
    border-radius: 8px;
    border: 1px solid white;
    padding: 0px 0px 20px 0px;
  } */
  .admin-dash-box {
    /* box-shadow: rgba(0, 0, 0, 0.24) 1px 1px 8px ; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px 0px;
    border-radius: 5px;
    border: 1px solid #dbd7d7;
    padding: 0px 0px 18px 0px;
}
  .subCategorylist-table-main .table {
    margin: 0px;
  }
  
  .subCategorylist-table-main .table .table-th {
    padding: 10px 10px 10px 20px;
    text-align-last: center;
  }
  
  .subCategorylist-table-main .table .table-td {
    padding: 1px 10px 1px 10px;
    text-align-last: center;
  }
  
  .subCategorylist-table-main .table .table-th:first-child {
    text-align: center;
  }
  
  .subCategorylist-table-main .table .table-th:nth-child(1),
  .subCategorylist-table-main .table .table-td:nth-child(1) {
    width: 10%;
  }
  
  .subCategorylist-table-main .table .table-th:nth-child(2),
  .subCategorylist-table-main .table .table-td:nth-child(2) {
    width: 25%;
  }
  
  .subCategorylist-table-main .table .table-th:nth-child(3),
  .subCategorylist-table-main .table .table-td:nth-child(3) {
    width: 25%;
  }
  
  .subCategorylist-table-main .table .table-th:nth-child(4),
  .subCategorylist-table-main .table .table-td:nth-child(4) {
    width: 15%;
  }

  .page-table-main .table-container {
    padding-bottom: 7px;
    background-color: transparent;
    box-shadow: none;
    overflow: auto;
    max-width: calc(100vw - 0px);
    max-height: 430px;
  }
  

  .page-table-main .table-th {
    font-size: 16px;
    line-height: 18px;
    color: #222326;
    font-family: "Poppins-Medium";
    padding: 10px 10px 10px 0px;
    border-bottom: 1px solid #d1d5db;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  
  /* style={{ position: "sticky", top: 0, background: "white", zIndex: 1 }} */
  
  .page-table-main .table .table-td:first-child {
    color: #111827;
  }
  
  .page-table-main .table-td {
    font-size: 13px;
    line-height: 18px;
    color: #23262d;
    font-family: "Poppins-Regular";
    padding: 10px 10px 10px 0px;
    border-bottom: 1px solid #d1d5db;
  }
  
  .Add_Btn {
    color: whitesmoke;
    text-transform: capitalize;
  }
  /* width */
  .page-table-main .table-container::-webkit-scrollbar {
    width: 8px;
    height: 5px;
  }
  
  /* Track */
  .page-table-main .table-container::-webkit-scrollbar-track {
    background: #d1d5db;
    border-radius: 5px;
  }
  
  /* Handle */
  .page-table-main .table-container::-webkit-scrollbar-thumb {
    /* background: #8888885c; */
    background: #0e3f55;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  .page-table-main .table-container::-webkit-scrollbar-thumb:hover {
    /* background: #555; */
    background: #114a65;
  }
  
  .admin-dashboard-table-row {
    border-bottom: 1px solid #d1d5db;
    padding: 20px 19px;
  }
  
  .subCategorylist-table-main .table {
    margin: 0px;
  }
  .action-btn {
    min-width: 15px !important;
  }
  
  .subCategorylist-table-main .table .table-th {
    padding: 10px 10px 10px 20px;
    text-align-last: center;
  }
  
  .subCategorylist-table-main .table .table-td {
    padding: 1px 10px 1px 10px;
    text-align-last: center;
  }
  
  .subCategorylist-table-main .table .table-th:first-child {
    text-align: center;
  }
  
  .pagination-main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
    margin-right: 20px;
  }
  
  .pagination-main .MuiPaginationItem-root {
    height: 20px;
    min-width: 20px;
    border-radius: 4px;
    padding: 0px;
    font-size: 12px;
    line-height: 18px;
    color: #6b7280;
    font-family: "Poppins-Regular";
    margin: 0 4px;
  }
  
  .pagination-main .MuiPaginationItem-root.Mui-selected {
    background-color: #233862;
    color: #ffffff;
  }
  
  
  
  .dropdown-box .form-control {
    width: 100%;
    position: relative;
  }
  
  .otp-input-box .form-group .form-control input::placeholder {
    color: #6b7280;
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins-Regular";
  }
  .otp-input-box .form-group .form-control input {
    max-width: 70px;
    text-align: center;
    color: #6b7280;
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins-Regular";
  }
  
  .userdata-btn-flex {
    display: inline-flex;
  }
  
  .userdata-btn-flex button {
    min-width: 30px;
    margin-top: 3px;
  }
  
  .MuiSwitch-thumb{
    margin-top: 1px !important;
  }
  
  /* add Category */
  
  .Add_Img {
    margin-top: 5px;
  }
  
  .MuiButtonBase-root{
    max-height: 35px !important;
  }
  
  .MuiInputBase-input {
    padding-top: 6px !important;
    padding-bottom: 3px !important;
    max-height: 25px !important;
  }
  
  .Add_Btn {
    color: white;
    text-transform: none;
  }
  
  .modal .add-user-modal-inner-main {
    padding: 24px 24px 30px 24px;
    max-width: 550px;
    width: 100%;
    border: 1px solid #707070;
    border-radius: 10px;
    overflow: auto;
    min-height: 5vh;
    max-height: 80%;
  }
  
  .modal .modal-title {
    color: #111827;
    font-size: 20px;
    line-height: 30px;
    font-family: "Poppins-Medium";
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .input-box .form-lable {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0px;
    font-family: "Poppins-Medium";
    margin-bottom: 6px;
    margin-top: 3px;
    color: #7a7d82;
  }
  
  .form-image {
    padding-left: 10px !important;
  }
  .input-box {
    margin-bottom: 28px;
  }
  .image-text {
    font-size: 15px !important;
    font-weight: 500 !important;
  }
  .admin-forgot-para {
    color: #114a65;
  }
  
  .input-box .MuiInputBase-root {
    width: 100%;
  }
  
  /* .input-box .MuiInputBase-input {
    padding: 10.5px 15px;
  } */
  
  
  .input-box .MuiInputBase-root fieldset:hover {
    border-color: transparent;
  }
  
  .modal-input-box {
    margin-bottom: 27px;
  }
  
  
  .modal-input-box .form-group .form-control input {
    /* padding: 0.5px 15px; */
    padding: 2px;
    padding-left: 8px;
    /* padding-bottom: 5px; */
    /* padding-bottom: 5px; */
  }
  
  .input-box .form-lable {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0px;
    font-family: "Poppins-Medium";
    margin-bottom: 6px;
    margin-top: 3px;
    color: #091c43;
  }
  
  .form-group .field-arrow-control input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  
  
  .form-group
    .field-arrow-control
    input[type="number"]::-webkit-outer-spin-button,
  .form-group
    .field-arrow-control
    input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .error-msg {
    text-align: left;
    color: red;
    margin-left: 15px !important;
    font-size: 13px !important;
  }
  
  .modal-user-btn-flex {
    display: flex;
    justify-content: flex-end;
    padding-top: 3px;
  }
  
  .discard-btn-main .discard-user-btn,
  .discard-btn-main .discard-user-btn:hover {
    min-width: 83px;
    margin-right: 10px;
    height: 36px;
  }
  
  .border-btn-main .border-btn {
    border: 1px solid #d1d5db;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 6px 14px 5px;
    font-size: 12px;
    line-height: 18px;
    color: red;
    font-family: "Poppins-Regular";
    text-transform: capitalize;
  }
  
  .border-btn-main .border-btn {
    border: 1px solid #d1d5db;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 6px 14px 5px;
    font-size: 12px;
    line-height: 18px;
    color: red;
    font-family: "Poppins-Regular";
    text-transform: capitalize;
  }
  
  .save-btn-main .save-user-btn,
  .save-btn-main .save-user-btn:hover {
    min-width: 87px;
    background-color: #072e41;
    color: #ffffff;
    height: 36px;
  }
  
  .user-save-icon {
    margin-right: 8px;
    object-fit: contain;
  }
  
  .modal-close-icon {
    cursor: pointer;
  }
   .input-file{
    padding-top: 10px !important;
   }
  