

.change-space {
    width: 380px;
    margin-bottom: 10px;
    margin-left: 15px
}

.position {
    margin-left: 110px;
    text-align: left;
}

.container-box{
    box-shadow: rgba(0, 0, 0, 0.24) 1px 3px 8px 1px;
    border-radius: 5px;
    border: 1px solid #d4d3d3;
    width: 460px;
    height: auto;
    padding: 25px;
    margin-left: 25px;
}

.error-msg {
    margin-left: 8px !important;
}