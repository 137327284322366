.table-td{
    white-space: nowrap !important;
}
.table-td-postalCode{
    width: 11% !important  ;
}

/* .table-td-company{
    width: 18% !important  ;
} */

.input-box .input-field input{
font-size: 14px;
display: flex;
justify-content: center;
align-items: center;
/* width: 220px; */
}


  .search-grey-img {
    position: absolute;
    left: 13px;
    top: 13px;
    height: 13px;
    width: 10px;
    margin-right: 8px;
  }
  
  
  /* .users-table tr th:nth-child(1) {
    max-width: 27%;
    width: 10%;
  }
  
  .users-table tr th:nth-child(2) {
    max-width: 27%;
    width: 25%;
  }
  
  .users-table tr th:nth-child(3) {
    max-width: 28%;
    width: 25%;
  }
  
  .users-table tr th:nth-child(4) {
    max-width: 20%;
    width: 20%;
  }
  
  .users-table tr th:nth-child(5) {
    max-width: 20%;
    width: 11%;
  } */
  
 .view-box{
    margin-left: 25px;
 }

 .txn-table-box{
  margin: 10px 15px 50px 25px !important;

 }

 .change-password-set {
  margin-bottom: 5px;

}


/* Reduce the font size of all text within .invoice */
.invoice {
  font-family: Arial, sans-serif;
  /* height: 900px; */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 0px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-size: 14px; 
  word-spacing:1px;/* Adjust the font size as needed */
}

/* Header styling */
.invoice-header h3{
text-align: center;
padding: 40px 10px 20px 10px;

}
.invoice-header h1 {
  font-size: 24px;
  margin: 0;
}

/* Table styling */
.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.invoice-table th {
  background-color: #f0f0f0;
}

/* Footer styling */
.invoice-table tfoot td {
  font-weight: bold;
}

/* Sender and recipient details */
.sender-details,
.recipient-details {
  width: 48%;
  display: inline-block;
  vertical-align: top;
}

/* Overall layout */
.invoice-details {
padding: 20px 0px 20px 0px;
}

.invoice-details h4{
  padding: 0px 0px 10px 0px;
}
.invoice-table tbody tr td{
  padding: 30px 10px 30px 10px;
}

/* Adjust spacing and margins as needed */
.modal-inner .pdfbutton {
  border: none;
  padding: 10px;
  float: right;
  color:#fff;

}

.modal-delete .delete-modal-inner-main-box {

  overflow-y: scroll;

  padding: 30px 24px 30px 24px;

  max-width: 650px;
  max-height: 550px;
  border-radius: 0px;
 
}


.delete-modal-inner-main-box {
  margin-left: 28% !important;
  margin-top: 6% !important;
  background-color: whitesmoke !important;
}

.header-contain-invoice{
  display: flex;
  padding: 10px 0px 10px 0px;
  justify-content: space-between;
}

.header-contain-invoice p{
  margin-bottom: 5px;
  
}


.logo-box  .invoice-logo{
  background-color: rgb(157, 156, 156);
  width: 45px;
  height: 40px;

border-radius: 5px;
}

.userlist-table{
  margin: 15px;
}

.sender-details p{
padding: 3px;
}
.recipient-details p{
  padding: 3px;
}

.userlist-table-main .table {
  margin: 0px;
}

.userlist-table-main .table .table-th {
  padding: 10px 10px 10px 20px;
  text-align-last: center;
}

.userlist-table-main .table .table-td {
  padding: 1px 10px 1px 10px;
  text-align-last: center;
}

.userlist-table-main .table .table-th:first-child {
  text-align: center;
}

.userlist-table-main .table .table-th:nth-child(1),
.userlist-table-main .table .table-td:nth-child(1) {
  width: 4%;
}

.userlist-table-main .table .table-th:nth-child(2),
.userlist-table-main .table .table-td:nth-child(2) {
  width: 15%;
}

.userlist-table-main .table .table-th:nth-child(3),
.userlist-table-main .table .table-td:nth-child(3) {
  width: 14%;
}

.userlist-table-main .table .table-th:nth-child(4),
.userlist-table-main .table .table-td:nth-child(4) {
  width: 26%;
}

.userlist-table-main .table .table-th:nth-child(5),
.userlist-table-main .table .table-td:nth-child(5) {
  width: 18%;
}

.userlist-table-main .table .table-th:nth-child(6),
.userlist-table-main .table .table-td:nth-child(6) {
  width: 8%;
}

.userlist-table-main .table .table-th:nth-child(7),
.userlist-table-main .table .table-td:nth-child(7) {
  width: 15%;
}

.userlist-table-main .table .table-th:nth-child(8),
.userlist-table-main .table .table-td:nth-child(8) {
  width: 22%;
}