.table-td{
    white-space: nowrap !important;
}
.userlist-data{

  height: 40px;

}

.categorylist-img{
  width: 50px;
  height: 40px;
    object-fit: contain;
    /* padding: 1px 0px 1px 0px !important; */
    border-radius: 5px !important;
}

.user-search-box {
    position: relative;
    margin-right: 10px;
  }
  
  .user-search-box .form-control input {
    padding: 6px 0px 7px 30px;
  }
  .user-search-box .form-control input::placeholder {
    color: #6b7280;
    font-size: 13px;
    line-height: 20px;
    font-family: "Poppins-Regular";
    opacity: 1;
  }

  .user-table-main .table {
    margin: 0px;
  }
  
  .user-table-main .table .table-th {
    padding: 10px 10px 10px 20px;
    text-align-last: center;
  }
  
  .user-table-main .table .table-td {
    padding: 1px 10px 1px 10px;
    text-align-last: center;
  }

  .user-table-main .table .table-th:first-child {
    text-align: center;
  }
  .user-table-main .table .table-th:nth-child(1),
  .user-table-main .table .table-td:nth-child(1) {
    width: 5%;
  }
  
  .user-table-main .table .table-th:nth-child(2),
  .user-table-main .table .table-td:nth-child(2) {
    width: 12%;
  }
  
  .user-table-main .table .table-th:nth-child(3),
  .user-table-main .table .table-td:nth-child(3) {
    width: 12%;
  }
  
  .user-table-main .table .table-th:nth-child(4),
  .user-table-main .table .table-td:nth-child(4) {
    width: 25%;
  }
  
  .user-table-main .table .table-th:nth-child(5),
  .user-table-main .table .table-td:nth-child(5) {
    width: 12%;
  }
  
  .user-table-main .table .table-th:nth-child(6),
  .user-table-main .table .table-td:nth-child(6) {
    width: 8%;
  }
  
  .user-table-main .table .table-th:nth-child(7),
  .user-table-main .table .table-td:nth-child(7) {
    width: 15%;
  }
  
  .user-table-main .table .table-th:nth-child(8),
  .user-table-main .table .table-td:nth-child(8) {
    width: 15%;
  }