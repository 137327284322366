.form-group .form-control input {
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    width: 100%;
    /* padding: 9px 15px; */
    background-color: transparent;
    color: #6B7280;
    font-size: 13px;
    line-height: 19px;
    font-family: 'Poppins-Regular';
}
.admin-tab{
   font-family: poppins-regular !important;
   color: #111827 !important;
   text-transform: capitalize !important;
}
.form-group .form-control input::placeholder {
    color: #6B7280;
    font-size: 12px;
    line-height: 20px;
    font-family: 'Poppins-Regular';
    opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    border-width: 0px;
}
.form-group .errormsg{
    color: red;
    font-size: 12px;
    padding: 4px;
}

.user-btn-flex {
    display: flex;
    justify-content: flex-end;
    margin-right:5% !important;
    align-items: center;
    margin-top: 10px;
}

.discard-btn-main .discard-user-btn,
.discard-btn-main .discard-user-btn:hover {
      min-width: 83px;
      margin-right: 10px;
      height: 36px;
}

.border-btn-main .border-btn {
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 6px 14px 5px;
    font-size: 12px;
    line-height: 18px;
    color: red;
    font-family: 'Poppins-Regular';
    text-transform: capitalize;
}

.save-btn-main .save-user-btn,
.save-btn-main .save-user-btn:hover {
      min-width: 87px;
      background-color: #233862 ;
      color: #FFFFFF;
      height: 36px;
}

.user-save-icon {
    margin-right: 8px;
    object-fit: contain;
}

.input-design-div .input-placeholder input::placeholder {
    color: #233862;
    line-height: 20px;
  
  }

  .input-design-div .password {
    margin-top: 20px !important;
  }

  .input-design-div.with-border input {
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0 15px;
    height: 40px;
  
    line-height: 28px;
  }
  
  .admin-tabpanel .edit-highlight-text {
    color: #111827;
    font-family: 'Poppins-Medium';
    margin-bottom: 4px;
    
}

.admin-tabpanel .edit-para-text {
    margin-bottom: 24px;
    color: #6B7280;
    text-align: left;
}

.admin-tabpanel>.MuiBox-root {
    padding: 24px 0px;
}

.page-border {
    border: 1px solid #D1D5DB;
    border-radius: 6px;
    padding: 20px;
}


.admin-tabpanel .edit-highlight-text {
    color: #111827;
    font-family: 'Poppins-Medium';
    margin-bottom: 4px;
    text-align: left !important;
}

.admin-submenu-listitem-main .admin-sidebar-link {
    display: block;
  }
  
  .admin-sidebar-submenulist .admin-sidebar-link {
    margin-left: 10px;
  }
  
  .admin-sidebar-submenulist
    .admin-sidebar-listitem:first-child
    .admin-sidebar-link {
    margin-top: 20px;
  }

  .admin-sidebar-list-main .admin-sidebar-link {
    font-size: 14px;
    line-height: 21px;
    font-family: "Poppins-Regular";
    color: #ffffff;
    display: flex;
    padding: 10px 12px 10px;
    margin-bottom: 10px;
    width: 100%;
    text-decoration: none;
  }
  

.common-para {
    font-size: 14px !important;
    line-height: 21px !important;
    font-weight: bold !important;
    font-family: 'Poppins-Regular';
}

.admin-tabpanel .edit-para-text {
    margin-bottom: 24px;
    color: #959ba7;
}
.edit-profile-flex {
    display: flex;
    align-items: center;
    /* margin-bottom: 20px; */
}

.file-upload-btn-main .file-upload-btn,
.file-upload-btn-main .file-upload-btn:hover {
      background-color: #FFFFFF;
      border-radius: 50%;
      height: 17px;
      width: 17px;
      min-width: 17px;
      padding: 0px;
      position: absolute;
      right: 0px;
      bottom: 13px;
      box-shadow: 0px 3px 6px #00000029;
}

.file-upload-btn-main {
      position: relative;
      max-width: max-content;
      margin-left: 20px !important;
}

.upload-profile-img {
    height: 60px;
    width: 80px;
    object-fit: cover;
    border-radius: 20%;
    margin-left: 30px;
}



.upload-icon-img {
    height: 10px;
    width: 10px;
    /* margin-top: 18px;
    margin-left: 8px; */
    object-fit: contain;
}



.form-lable{
    color: #2B478B !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.input-box .MuiInputBase-root {
    width: 100%;
    height: 35px;
}
