.back-header {
  background: linear-gradient(180deg, #11074C 0%, #20222C 100%);
  padding: 31.5px 30px;
  position: relative;
}

.backheader-inner .back {
  color: #fff;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  text-decoration: none;
  font-family: 'poppins-semibold';
}

.back img {
  margin-right: 11px;
}

.res-back-header{
  position: fixed;
      left: 0;
      right: 0;
      top: 0;
  }
  .bottom-box {
      display: flex;
      justify-content: center;
    }

    .box-text {
      margin-bottom: 36px;
    }

    .input-design-div .input-placeholder input::placeholder {
      color: #02091c !important;
      line-height: 18px !important;
      /* font-family: 'poppines-bold' !important; */
      font-weight: 600 !important;
      font-size: 12px !important;
    }
  
    
    .box-text p {
      font-size: 18px;
      line-height: 23px;
      color: #2B478B;
      font-family: 'poppins-bold';
      text-align: center;
    }
    .login-btn button {
      /* width: 41.784%; */
      width: 100% ;
    }
    .bottom-reset-btn-main {
      margin: 30px 0px 0px;
    }
    .reset-box-text p {
      font-size: 12px;
      /* color: #233862; */
      color: #2B478B;
      line-height: 18px;
      font-family: 'poppins-regular';
      text-align: center;
      margin-bottom: 22px;
    }
    .reset-text p {
      color: #233862 !important;
    }
    
    .reset-text {
      margin-bottom: 17px !important;
    }
    
    .main-box {
      max-width: 391px !important;
      width: 391px;
      flex: 0 0 391px;
      padding: 30px;
      border: 1px solid #2B478B;
      border-radius: 10px;
    
    }
  .dark-ugo-logo-inner p {
      color: black;
      font-size: 13px;
      line-height: 23px;
      font-family: 'poppins-regular';
      text-align: center;
      letter-spacing: 1px;
    }

  .dark-ugo-logo-text p {
      color: black;
      font-family: 'poppins-bold';
      font-size: 25px;
      line-height: 28px;
      margin: 10px 0px;
      letter-spacing: 2px;
    }

  .dark-logo-set img {
      height: 77px;
      width: 100px;
      object-fit: contain;
    }

  .ugo-logo-set {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      flex-direction: column;
    
    }
  .auto-innercontent {
      height: calc(100vh - 84px);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      flex-direction: column;
      margin-top: 84px;
    }
    

  .backheader-inner .back {
      color: #fff;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      text-decoration: none;
      font-family: 'poppins-semibold';
    }