.sidebar-category-icon {
color:#83a1dd !important    
}
.sidebar-setting-icon{
    color:#83a1dd !important  
}

/* .admin-sidebar-link{
    margin-bottom: 10px !important;
} */

