.search-grey-img {
    position: absolute;
    left: 13px;
    top: 13px;
    height: 13px;
    width: 10px;
    margin-right: 8px;
  }
  
  

  .btn-main-primary{
    margin-left: 10px;
  }

  /* .dashboard-content{
    margin: 10px;
  } */

  .user-list-flex-b {
margin-bottom: 0px;
  }

  .table-main tr td {
    padding: 0px 0px 0px 20px !important;
}


/* Reduce the font size of all text within .invoice */
.invoice {
  font-family: Arial, sans-serif;
  /* height: 900px; */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 0px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-size: 14px; 
  word-spacing:1px;/* Adjust the font size as needed */
}

/* Header styling */
.invoice-header h3{
text-align: center;
padding: 40px 10px 20px 10px;

}
.invoice-header h1 {
  font-size: 24px;
  margin: 0;
}

/* Table styling */
.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.invoice-table th {
  background-color: #f0f0f0;
}

/* Footer styling */
.invoice-table tfoot td {
  font-weight: bold;
}

/* Sender and recipient details */
.sender-details,
.recipient-details {
  width: 48%;
  display: inline-block;
  vertical-align: top;
}

/* Overall layout */
.invoice-details {
padding: 20px 0px 20px 0px;
}

.invoice-details h4{
  padding: 0px 0px 10px 0px;
}
.invoice-table tbody tr td{
  padding: 30px 10px 30px 10px;
}

/* Adjust spacing and margins as needed */
.modal-inner .pdfbutton {
  border: none;
  padding: 10px;
  float: right;
  color:#fff;

}

.modal-delete .delete-modal-inner-main-box {

  overflow-y: scroll;

  padding: 30px 24px 30px 24px;

  max-width: 650px;
  max-height: 550px;
  border-radius: 0px;
 
}


.delete-modal-inner-main-box {
  margin-left: 28% !important;
  margin-top: 6% !important;
  background-color: whitesmoke !important;
}

.header-contain-invoice{
  display: flex;
  padding: 10px 0px 10px 0px;
  justify-content: space-between;
}

.header-contain-invoice p{
  margin-bottom: 5px;
  
}


.logo-box  .invoice-logo{
  background-color: rgb(157, 156, 156);
  width: 45px;
  height: 40px;

border-radius: 5px;
}

.userlist-table{
  margin: 15px;
}

.sender-details p{
padding: 3px;
}
.recipient-details p{
  padding: 3px;
}





